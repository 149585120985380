export default {
    methods: {
        doAddQuestion: function(data, callback){
            this.$store.dispatch('addQuestion', data)
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((error) => {
                callback && callback(null, error)
            })
        }
    },
}