<template>
    <div id="add-ask">
        <ul class="ask-tabbars">
            <li class="ask-tabbar current"><router-link to="/ask/postnew">我要咨詢</router-link></li>
            <li class="ask-tabbar"><router-link to="/ask/list">推薦問答</router-link></li>
        </ul>
        <div class="unlogin" v-if="!userInfo">
            <img src="../../../assets/imgs/pc/img_kongbai@2x.png" alt="">
            <div class="unlogin-notice">
                還沒有<router-link to="/login" class="do-login">登錄</router-link>，無法進行咨詢
            </div>
        </div>
        <div class="ask-form" v-if="userInfo">
            <div class="ask-form-t">
                <div class="ask-user">患者信息</div>
                <div class="ask-user-gender">
                    <span :class="{'gender': true, 'gender-male':true, 'checked': question.gender==1}" @click="changeGender(1)">男</span>
                    <span :class="{'gender': true, 'gender-male':true, 'checked': question.gender==2}" @click="changeGender(2)">女</span>
                </div>
                <i class="divider"></i>
                <input type="number" v-model="question.age" class="ask-user-age">
                <a-dropdown :trigger="['click']">
                    <div class="ask-user-age-unit" :data-value="ageUnitValue">{{ageUnitName || "歲"}}</div>
                    <a-menu slot="overlay">
                        <a-menu-divider />
                        <a-menu-item @click="onAgeUnitItemClick(ageUnit.name, ageUnit.value)" :key="ageUnit.value" v-for="ageUnit in ageUnits">
                            {{ageUnit.name}}
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
            <div class="ask-form-b">
                <div class="ask-form-item ask-category">
                    <span class="hint">疾病分科：</span>
                    <a-dropdown :trigger="['click']">
                        <div class="ask-category-v" :data-value="question.illnesscategoryid">{{categoryName || "科別"}}</div>
                        <a-menu slot="overlay">
                            <a-menu-item @click="onCategoryItemClick('', '')" key="-1">科別</a-menu-item>
                            <a-menu-divider />
                            <a-menu-item @click="onCategoryItemClick(category.codedesc, category.codeid)" :key="category.codeid" v-for="category in categorys" v-text="category.codedesc"></a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
                <div class="ask-form-item ask-title">
                    <span class="hint">問題標題：</span>
                    <input type="text" class="ask-title-v" placeholder="一句話描述您的問題" @input="onInput('title')" v-model="question.title">
                </div>
                <div class="ask-form-item ask-desc">
                    <span class="hint">填寫我的問題</span>
                    <textarea cols="30" rows="6" class="ask-desc-v" placeholder="請描述病情（發病時間，主要症狀），曾經治療情況和效果，想得到怎樣的幫助" @input="onInput('note')" v-model="question.note"></textarea>
                </div>
                <div class="btn-submit" @click="addQuestion"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '提交給醫師'}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import vFoot from '@/layouts/Foot.vue'
    import add_ask from '@/views/mixins/add_ask'
	export default {
        mixins: [add_ask],
        components: {
            vFoot
        },
        data(){
            return {
                userInfo: this.$store.getters.userInfo,
                loading: false,
                categoryName: '',
                categorys: this.$store.getters.askCategorys || [],
                cName: '',
                cValue: '',
                ageUnits: [
                    { name: '歲', value: '1' },
                    // { name: '個月', value: '2' },
                    // { name: '週', value: '3' },
                ],
                ageUnitName: '歲',
                ageUnitValue: '1',
                question: {
                    age: '',
                    gender: 1,
                    createdby: '',
                    illnesscategoryid: '',
                    imageList: [],
                    title: '',
                    note: '',
                    ip: '',
                }
            }
        },
        created: function(){
            if(!this.categorys.length) {
                this.$store.dispatch('getAskCategorys', {'langcode': 'B5'})
                .then((rs)=>{
                    this.categorys = rs || []
                }) 
            }
        },
        mounted: function(){
            this.userInfo = this.$store.getters.userInfo
            this.question.gender = (this.userInfo && this.userInfo.clientsex) || 1
            this.question.createdby = this.$store.getters.clientId
        },
        methods: {
            onInput: function(attr) {
                this.question[attr] = this.question[attr].trim() 
            },
            addQuestion: function(){
                if(this.loading) return

                if(this.question.age == '') {
                    this._toast.warning(this, '請輸入患者年齡')
                    return
                }
                
                if(! this.question.illnesscategoryid) {
                    this._toast.warning(this, '請選擇科別')
                    return
                }
                
                if(! this.question.title.trim()) {
                    this._toast.warning(this, '請填寫問題標題')
                    return
                }
                
                if(! this.question.note) {
                    this._toast.warning(this, '請描述病情')
                    return
                }
                this.loading = true
                this.doAddQuestion(this.question, (data, error)=>{
                    this.loading = false
                    if(!error) {
                        this._toast.success(this, '添加咨詢成功！')
                        setTimeout(() => {
                            window.location.reload()    
                        }, 1500)
                    } else {
                        this._toast.warning(this, error)
                    }
                })
            },
            changeGender: function(v) {
                this.question.gender = v
            },
            onCategoryItemClick: function(name, value) {
                this.categoryName = name
                this.question.illnesscategoryid = value
            },
            onAgeUnitItemClick: function(name, value) {
                this.ageUnitName = name
                this.ageUnitValue = value
            }
        }
    }
</script>

<style lang="scss" scoped>
    #add-ask {
        display: flex;
        flex-direction: column;
        user-select: none;
    }

    #add-ask > .ask-tabbars {
        width: 692px;
        height: 60px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
    }

    #add-ask > .ask-tabbars::after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: #E8E8E8;
        left: 12px;
        right: 12px;
        bottom: 0;
    }

    #add-ask > .ask-tabbars > .ask-tabbar {
        margin-left: 50px;
    }

    #add-ask > .ask-tabbars > .ask-tabbar:nth-child(1) {
        margin-left: 0px;
    }

    #add-ask > .ask-tabbars > .ask-tabbar,
    #add-ask > .ask-tabbars > .ask-tabbar > a {
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        color: #231F20;
    }

    #add-ask > .ask-tabbars > .ask-tabbar.current,
    #add-ask > .ask-tabbars > .ask-tabbar.current > a,
    #add-ask > .ask-tabbars > .ask-tabbar:hover,
    #add-ask > .ask-tabbars > .ask-tabbar:hover > a {
        font-weight: bold;
        color: #36C4D0;
    }

    #add-ask > .aq-list,
    #add-ask > .ask-form {
        width: 692px;
    }

    #add-ask > .aq-list {
        background-color: #fff;
    }


    #add-ask > .ask-form > .ask-form-t {
        width: 692px;
        height: 80px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user {
        width: 64px;
        padding-top: 53px;
        font-size: 16px;
        color: #636363;
        font-weight: 400;
        line-height: 16px;
        position: relative;
        margin-left: 20px;
        display: inline-block;
        height: 80px;
        box-sizing: border-box;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 34px;
        height: 34px;
        top: 12px;
        background-image: url(../../../assets/imgs/pc/img_huanzhe@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-gender {
        width: 189px;
        box-sizing: border-box;
        padding: 0px 40px 0px 49px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-gender > span {
        width: 20px;
        height: 16px;
        position: relative;
        display: inline-block;
        padding-left: 20px;
        cursor: pointer;
        font-size: 16px;
        color: #636363;
        font-weight: 400;
        line-height: 16px;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-gender > span::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 16px;
        height: 16px;
        background-image: url(../../../assets/imgs/pc/img_huichange@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;

    }

    #add-ask > .ask-form > .ask-form-t .ask-user-gender > span.checked {
        font-weight: bold;
        color: #231F20;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-gender > span.checked::before {
        background-image: url(../../../assets/imgs/pc/img_change@2x.png);

    }

    #add-ask > .ask-form > .ask-form-t .divider {
        width: 1px;
        height: 36px;
        background-color: #E0E0E0;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-age {
        display: inline-block;
        margin-left: 40px;
        width: 76px;
        height: 26px;
        box-sizing: border-box;
        border: 1px solid #7BD5DF;
        line-height: 26px;
        text-align: center;
        font-weight: bold;
        color: #231F20;
        font-size: 16px;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-age-unit {
        display: inline-block;
        margin-left: 8px;
        min-width: 52px;
        height: 26px;
        box-sizing: border-box;
        border: 1px solid #7BD5DF;
        line-height: 26px;
        text-align: center;
        font-weight: bold;
        color: #231F20;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        padding-right: 27px;
        padding-left: 9px;
        transition: all .2s linear;
    }

    #add-ask > .ask-form > .ask-form-t .ask-user-age-unit::after {
        content: '';
        width: 10px;
        height: 7px;
        position: absolute;
        right: 9px;
        top: 9px;
        background-image: url(../../../assets/imgs/pc/img_xial2@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #add-ask > .ask-form > .ask-form-b {
        width: 692px;
        height: 391px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 10px;
    }

    #add-ask > .ask-form > .ask-form-b .ask-form-item {
        display: flex;
        flex-direction: row;
        margin: 30px 20px 0px 20px;
    }

    #add-ask > .ask-form > .ask-form-b .hint {
        height: 26px;
        line-height: 26px;
        font-weight: 400;
        font-size: 16px;
        color: #636363;
    }

    #add-ask > .ask-form > .ask-form-b .ask-category {
        position: relative;
    }

    #add-ask > .ask-form > .ask-form-b .ask-category-v {
        width: 118px;
        height: 26px;
        box-sizing: border-box;
        border: 1px solid #7BD5DF;
        position: relative;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        padding-left: 14px;
        line-height: 26px;
        cursor: pointer;
    }

    #add-ask > .ask-form > .ask-form-b .ask-category-v::before {
        content: '';
        position: absolute;
        right: 30px;
        top: 0px;
        bottom: 0px;
        width: 1px;
        background-color: #7BD5DF;
    }

    #add-ask > .ask-form > .ask-form-b .ask-category-v::after {
        content: '';
        position: absolute;
        right: 10px;
        top: 9px;
        width: 10px;
        height: 7px;
        background-image: url(../../../assets/imgs/pc/img_xial2@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #add-ask > .ask-form > .ask-form-b .ask-title-v {
        width: 544px;
        height: 26px;
        line-height: 15px;
        box-sizing: border-box;
        border: 1px solid #7BD5DF;
        padding: 6px 8px 5px 8px;
        display: inline-block;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }

    #add-ask > .ask-form > .ask-form-b .ask-form-item.ask-desc {
        display: block;
    }

    #add-ask > .ask-form > .ask-form-b .ask-form-item.ask-desc > .hint {
        display: block;
        position: relative;
        font-size: 16px;
        line-height: 16px;
        color: #231F20;
        height: 16px;
        padding-left: 23px;
    }

    #add-ask > .ask-form > .ask-form-b .ask-form-item.ask-desc > .hint::before {
        position: absolute;
        content: '';
        left: 0px;
        bottom: 1px;
        width: 15px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_fankui@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    #add-ask > .ask-form > .ask-form-b .ask-form-item.ask-desc > .ask-desc-v {
        display: block;
        width: 628px;
        height: 80px;
        background-color: #EBF4F6;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        padding: 16px 14px;
        margin-top: 10px;
    }

    #add-ask > .ask-form > .ask-form-b > .btn-submit {
        width: 168px;
        height: 40px;
        background-color: #36C4D0;
        color: #fff;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 39px;
        margin-left: 262px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #add-ask > .ask-form > .ask-form-b > .btn-submit:active {
        opacity: 0.7;
    }

    #add-ask .unlogin {
        display: flex;
        height: 482px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
    }

    #add-ask .unlogin > img {
        margin-bottom: 100px;
        width: 451px;
        height: 273px;
    }

    #add-ask .unlogin > .unlogin-notice {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #1B2122;
    }

    #add-ask .unlogin > .unlogin-notice > a {
        font-weight: 400;
        font-size: 18px;
        color: #FF8000;
    }


    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>
